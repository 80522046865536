import React from 'react'
import { IconProps } from './Icon'
import { StyledIcon } from './styles'

export const GithubIcon: React.FC<Omit<IconProps, 'iconName'>> = ({
  width,
  height
}) => {
  return (
    <StyledIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
    >
      <path d='M46.598 13.05c-2.235-3.827-5.262-6.855-9.09-9.09C33.684 1.728 29.504.61 24.973.61c-4.532 0-8.707 1.117-12.535 3.35-3.825 2.235-6.856 5.263-9.09 9.09C1.118 16.876 0 21.056 0 25.587c0 5.441 1.586 10.332 4.766 14.68 3.175 4.347 7.277 7.355 12.304 9.023.586.11 1.02.031 1.301-.227.281-.261.426-.585.426-.976l-.02-1.754a272.34 272.34 0 01-.015-2.895l-.746.13c-.477.09-1.078.124-1.805.113a13.61 13.61 0 01-2.262-.227 5.03 5.03 0 01-2.18-.976 4.116 4.116 0 01-1.43-1.997l-.323-.75c-.22-.5-.559-1.05-1.024-1.656-.469-.61-.937-1.02-1.414-1.238l-.23-.164a2.306 2.306 0 01-.422-.387 1.873 1.873 0 01-.293-.457c-.063-.152-.012-.277.164-.375.172-.098.488-.144.941-.144l.653.097c.433.086.968.344 1.609.778.637.433 1.164 1 1.574 1.691.5.89 1.102 1.566 1.805 2.035.707.465 1.418.7 2.133.7.715 0 1.332-.055 1.851-.165.52-.11 1.008-.27 1.465-.488.195-1.453.727-2.57 1.594-3.348a22.96 22.96 0 01-3.336-.586c-.984-.261-2.004-.683-3.055-1.27-1.05-.585-1.926-1.312-2.617-2.179-.695-.867-1.266-2.004-1.707-3.414-.445-1.41-.668-3.035-.668-4.879 0-2.62.856-4.855 2.57-6.695-.804-1.973-.726-4.184.227-6.637.629-.195 1.562-.047 2.797.442 1.234.488 2.14.906 2.715 1.25.574.347 1.035.64 1.382.879a23.12 23.12 0 016.243-.844c2.148 0 4.226.281 6.246.844l1.234-.782c.844-.52 1.844-.996 2.992-1.43 1.149-.433 2.028-.554 2.633-.359.977 2.453 1.063 4.664.262 6.637 1.71 1.84 2.57 4.074 2.57 6.7 0 1.839-.226 3.472-.668 4.89-.445 1.422-1.02 2.558-1.722 3.414-.708.86-1.583 1.578-2.637 2.164-1.051.586-2.07 1.008-3.055 1.27-.988.257-2.098.453-3.332.585 1.125.973 1.688 2.512 1.688 4.618v6.859c0 .39.136.715.41.977.27.257.695.335 1.281.226 5.031-1.668 9.133-4.676 12.309-9.023 3.175-4.348 4.765-9.243 4.765-14.684-.004-4.527-1.12-8.707-3.351-12.531zm0 0' />
    </StyledIcon>
  )
}
